import * as React from 'react'
import PropTypes from 'prop-types'

import * as Agent from './agent'
import * as ClientConfig from './client-configuration'
import * as Constants from './constants'
import * as Cookies from './cookies-module'
import * as Http from './http-module'

const FetchOptionsContext = React.createContext(null)

export function Provider(props) {
  const acceptLanguage = Http.useRequestHeader('Accept-Language')
  const locale = Http.useLocale()
  const clientType = ClientConfig.useClientType()
  const [countryCode] = ClientConfig.useCountryCode()
  const [language] = ClientConfig.useLanguage()
  const url = ClientConfig.useGraphqlUrl()

  const [token] = Cookies.useCookie(Constants.CookieKeys.TOKEN)
  const [abUserId] = Cookies.useCookie(Constants.CookieKeys.AB_USER_ID)

  const agent = React.useMemo(() => Agent.getAgentByUrl(url), [url])

  const headers = React.useMemo(
    () => ({
      ...(acceptLanguage && { 'Accept-Language': acceptLanguage }),
      ...(token && { Authorization: token }),
      ...(abUserId && { 'Frontend-Ab-User-Id': abUserId }),
      ...(clientType && { 'Frontend-Client-Type': clientType }),
      ...((locale.region || countryCode) && {
        'Frontend-Country-Code': locale.region || countryCode,
      }),
      ...((locale.language || language) && {
        'Frontend-Language': locale.language || language,
      }),
    }),
    [
      acceptLanguage,
      token,
      abUserId,
      clientType,
      locale.region,
      countryCode,
      locale.language,
      language,
    ]
  )

  const value = React.useMemo(
    () => ({
      headers,
      agent,
    }),
    [headers, agent]
  )

  return (
    <FetchOptionsContext.Provider value={value}>
      {props.children}
    </FetchOptionsContext.Provider>
  )
}

Provider.displayName = 'FetchOptionsContext.Provider'

Provider.propTypes = {
  children: PropTypes.node,
}

export function useContext() {
  return React.useContext(FetchOptionsContext)
}
