import * as React from 'react'

import { ClientType } from '../constants'

export function useClientTypeObserver(onChange) {
  React.useEffect(() => {
    const isTouchDevice =
      'ontouchstart' in window ||
      navigator.MaxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0

    const clientType = isTouchDevice
      ? ClientType.MOBILE_BROWSER
      : ClientType.BROWSER

    onChange(clientType)
  }, [onChange])
}
