import * as R from 'ramda'
import { createSelector } from 'reselect'

const EMPTY_ARRAY = []

/**
 * @param {Object} state
 * @param {Object} props
 * @param {string} props.catalog
 * @returns {Array.<number>} catalog category ids
 */
export function getGameCategoryIds(state, props) {
  return R.pathOr(EMPTY_ARRAY, ['catalogs', props.catalog, 'categories'], state)
}

/**
 * @param {Object} state
 * @returns {Object} categories
 */
export function getGameCategories(state) {
  return R.path(['categories'], state)
}

/**
 * @param {Object} state
 * @param {Object} props
 * @param {string} props.catalog
 * @returns {function}
 */
export function createGetGameCatalogIndex() {
  return createSelector(
    [getGameCategoryIds, getGameCategories],
    (categoriesIds, categories) => {
      return R.sort(
        (a, b) =>
          R.indexOf(a.id, categoriesIds) - R.indexOf(b.id, categoriesIds),
        R.values(R.pick(categoriesIds, categories))
      )
    }
  )
}

/**
 * @param {Object} state
 * @param {Object} props
 * @param {string} props.catalog
 * @param {string} props.categoryKey
 * @returns {Object} category
 */
export const getGameCategory = createSelector(
  [
    (state, props) => R.path(['categoryKey'], props),
    getGameCategoryIds,
    getGameCategories,
  ],
  (categoryKey, categoriesIds, categories) => {
    return R.find(R.pathEq(['key'], categoryKey))(
      R.values(R.pick(categoriesIds, categories))
    )
  }
)

/**
 * @param {Object} state
 * @param {Object} props
 * @param {string} props.catalog
 * @param {string} props.categoryKey
 * @returns {function}
 */
export function createGetGameCategoryId() {
  return createSelector([getGameCategory], category => {
    return R.path(['id'], category)
  })
}

/**
 * @param {Object} state
 * @param {Object} props
 * @param {number} props.category
 * @returns {Array.<number>} sections ids
 */
export function getGameSectionsIds(state, props) {
  return R.pathOr(
    EMPTY_ARRAY,
    ['categories', props.category, 'sections'],
    state
  )
}

/**
 * @param {Object} state
 * @returns {Object} sections
 */
export function getGameSections(state) {
  return R.path(['sections'], state)
}

/**
 * @param {Object} state
 * @returns {Object} games
 */
export function getGames(state) {
  return R.path(['games'], state)
}

/**
 * @param {Object} state
 * @param {Object} props
 * @param {number} props.section
 * @returns {Array.<number>} catalog games ids
 */
export function getGamesIds(state, props) {
  return R.pathOr(EMPTY_ARRAY, ['sections', props.section, 'games'], state)
}

/**
 * @param {Object} state
 * @param {Object} props
 * @param {string} props.category
 * @returns {function}
 */
export function createGetGameSections() {
  return createSelector(
    [getGameSectionsIds, getGameSections],
    (sectionsIds, sections) =>
      R.sort(
        (a, b) =>
          R.indexOf(R.path(['id'], a), sectionsIds) >
          R.indexOf(R.path(['id'], b), sectionsIds)
            ? 1
            : -1,
        R.values(R.pick(sectionsIds, sections))
      )
  )
}

/**
 * @param {Object} state
 * @param {Object} props
 * @param {string} props.id
 * @returns {Object} game
 */
export function getGame(state, props) {
  return R.path(['games', props.id], state)
}
