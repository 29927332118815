import * as R from 'ramda'
import Cookie from 'cookie'
import URL from 'url'
import parseResponseCookies from 'set-cookie-parser'

/**
 * @typedef {Object} LocaleDescriptor
 * @prop {?string} language
 * @prop {?string} region
 */

const LOCALE_RE = /^([a-z]{2})-([a-z]{2})$/i

/**
 * Get locale object from URL
 * @param {?string} url
 * @returns {LocaleDescriptor}
 */
export function localeFromUrl(url) {
  const { pathname } = URL.parse(R.defaultTo('/', url))
  const [locale] = R.split('/', R.defaultTo('/', R.drop(1, pathname)))

  if (typeof locale === 'string' && R.test(LOCALE_RE, locale)) {
    const [language, region] = R.tail(R.match(LOCALE_RE, locale))

    const normalizedLanguage = R.toLower(language)
    const normalizedRegion = R.toUpper(region)

    return {
      language: normalizedLanguage,
      region: normalizedRegion,
      slug: `${normalizedLanguage}-${normalizedRegion}`,
    }
  }

  return {}
}

/**
 * @typedef {Object} CookieOptions
 * @property {?string} domain
 * @property {?Date} expires
 * @property {?boolean} httpOnly
 * @property {?number} maxAge
 * @property {?string} path
 * @property {?(boolean|"none"|"lax"|"strict")} sameSite
 * @property {?boolean} secure
 */

/**
 * @typedef {Object} CookieDescriptor
 * @property {string} name
 * @property {?string} value
 * @property {CookieOptions} options
 */

/**
 * Update given Set-Cookie header value
 *
 * Replace existing cookie value or append it
 *
 * @param {string|Array.<string>} responseCookies `Set-Cookie` header value
 * @param {CookieDescriptor} cookie
 */
export function setCookie(responseCookies, { name, options, value }) {
  if (typeof responseCookies === 'string') {
    return setCookie([responseCookies], { name, options, value })
  }

  const prevCookies = parseResponseCookies(responseCookies)

  const index = R.findIndex(R.propEq('name', name), prevCookies)

  const cookie = Cookie.serialize(name, value, options)

  if (index >= 0) {
    return R.update(index, cookie, responseCookies)
  }

  return R.append(cookie, responseCookies)
}
