import * as React from 'react'
import PropTypes from 'prop-types'
import { Provider, createClient, dedupExchange, fetchExchange } from 'urql'

import { cacheExchange } from '@urql/exchange-graphcache'
import { devtoolsExchange } from '@urql/devtools'
import { suspenseExchange } from '@urql/exchange-suspense'

import * as ClientConfig from './client-configuration'
import * as FetchOptions from './fetch-options'
import { loggerExchange } from './logger-exchange'

function applyWhen(cond, exchange) {
  if (cond()) {
    return exchange
  }

  return ({ forward }) => forward
}

export function GraphqlProvider(props) {
  const url = ClientConfig.useGraphqlUrl()
  const { headers, agent } = FetchOptions.useContext()

  const client = React.useMemo(
    () =>
      createClient({
        exchanges: [
          applyWhen(
            () => process.env.NODE_ENV !== 'production',
            devtoolsExchange
          ),
          loggerExchange,
          dedupExchange,
          applyWhen(() => Boolean(process.browser), suspenseExchange),
          props.ssrCache,
          cacheExchange({
            keys: {
              PlayerProfile: data => data.email,
              Catalog: data => data.kind,
              Configuration: data => data.brand,
              Country: data => data.alpha2,
              Currency: data => data.code,
              Department: data => data.primary,
              Locale: data => data.slug,
              Moment: data => `${data.gameId}-${data.settleTimestamp}`,
              Provider: data => data.name,
              SeoMetadata: data => data.url,
              Player: data => {
                if (data.profile?.email) {
                  return data.profile.email
                }
                if (data.session?.token) {
                  return data.session.token
                }
                return null
              },
              Session: data => data.token,
              PhoneNumber: data => `${data.prefix}${data.number}`,
              PlayerAddress: () => null,
              PlayerAttribution: data =>
                `${data.affiliateId}_${data.affiliateClickId}_${data.affiliateTag}`,
              PlayerAccount: () => null,
              PlayerInfo: () => null,
            },
          }),
          fetchExchange,
        ],
        fetchOptions: {
          agent,
          headers,
        },
        // We ran into issue with thrown promises not being caught by React
        // Reconciler, which caused app crash/
        // TODO: Enable client-side Suspense when possible
        suspense: !process.browser,
        url,
      }),
    [agent, headers, props.ssrCache, url]
  )

  return <Provider value={client}>{props.children}</Provider>
}

GraphqlProvider.propTypes = {
  children: PropTypes.element,
  ssrCache: PropTypes.func,
  url: PropTypes.string,
}
