import * as React from 'react'
import PropTypes from 'prop-types'

import * as Common from '@rushplay/common'

import { Button } from '../button'

const DEBUG_ALL = 'all'
const DEBUG_MISSING = 'missing'
const DEBUG_OFF = 'off'

const DegbugTranslationsContext = React.createContext(null)

export function Provider(props) {
  const [debug, setDebugValue] = React.useState(DEBUG_OFF)

  return (
    <DegbugTranslationsContext.Provider value={{ debug, setDebugValue }}>
      {props.children}
    </DegbugTranslationsContext.Provider>
  )
}

Provider.propTypes = {
  children: PropTypes.element,
}

export function useContext() {
  return React.useContext(DegbugTranslationsContext)
}

export function DegbugTranslations() {
  const { setDebugValue } = useContext()

  return (
    <>
      <Common.Box textAlign="center" mb="0">
        Debug Translations
      </Common.Box>
      <Common.Flex justifyContent="center">
        <Button onClick={() => setDebugValue(DEBUG_ALL)}>DEBUG_ALL</Button>
        <Common.Space x="0">
          <Button onClick={() => setDebugValue(DEBUG_MISSING)}>
            DEBUG_MISSING
          </Button>
        </Common.Space>
        <Button onClick={() => setDebugValue(DEBUG_OFF)}>DEBUG_OFF</Button>
      </Common.Flex>
    </>
  )
}
