import * as React from 'react'
import * as ReactRedux from 'react-redux'

import * as Herz from '@rushplay/herz'
import * as Locks from '@rushplay/compliance/locks'

import * as Configuration from './configuration'

export function FastTrack() {
  const session = Herz.Auth.useSession()
  const fastTrackScript = ReactRedux.useSelector(state =>
    Configuration.getFastTrackScript(state.configuration)
  )
  const [isLoaded, setisLoaded] = React.useState(false)

  const hasLocks = ReactRedux.useSelector(state => Locks.hasLocks(state.locks))

  React.useEffect(() => {
    // load script
    window.fasttrackbrand = 'herogaming'
    window.fasttrack = {
      integrationVersion: 1.1,
      autoInit: false,
      inbox: {
        enable: true,
      },
    }

    const fastTrackCrmScript = window.document.createElement('script')
    fastTrackCrmScript.src = fastTrackScript
    fastTrackCrmScript.async = true
    fastTrackCrmScript.onload = () => {
      new window.FastTrackLoader()
      setisLoaded(true)
    }

    document.body.appendChild(fastTrackCrmScript)
  }, [])

  React.useEffect(() => {
    if (!hasLocks && session.prefixedToken && isLoaded) {
      // using interval to retry initialization if fast-track object was not yet attached
      const interval = setInterval(() => {
        if (window.FasttrackCrm) {
          clearInterval(interval)
          // re-initialize when prefix token is changed
          window.sid = session.prefixedToken
          window.FasttrackCrm && window.FasttrackCrm.init()
        }
      }, 1000)

      return () => clearInterval(interval)
    }
  }, [session.prefixedToken, isLoaded, hasLocks])

  return null
}
