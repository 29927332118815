import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'

import * as Configuration from './configuration'
import * as Http from './http-module'

const trailingSlashExceptedRoutes = ['/']

export function SeoCanonicals() {
  const basename = Http.useBasename()
  const location = ReactRouter.useLocation()

  const origin = ReactRedux.useSelector(state =>
    Configuration.getOrigin(state.configuration)
  )

  // Kill trailing slashes for non-excepted pages
  const pathname = R.dropLastWhile(
    lastChar =>
      R.not(R.includes(location.pathname, trailingSlashExceptedRoutes)) &&
      R.equals('/', lastChar),
    location.pathname
  )

  return (
    <Helmet>
      <link rel="canonical" href={`${origin}${basename}${pathname}`} />
    </Helmet>
  )
}
