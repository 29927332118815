import * as R from 'ramda'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import * as Http from './http-module'
import { redirect } from './http'

const STATUS_CHANGED = 'STATUS_CHANGED'
const URL_CHANGED = 'URL_CHANGED'

function changeStatus(status) {
  return {
    type: STATUS_CHANGED,
    payload: status,
  }
}

function changeUrl(url) {
  return {
    type: URL_CHANGED,
    payload: url,
  }
}

function reducer(state, action) {
  switch (action.type) {
    case STATUS_CHANGED: {
      return R.assoc('statusCode', action.payload, state)
    }

    case URL_CHANGED: {
      return R.assoc('url', action.payload, state)
    }

    default: {
      return state
    }
  }
}

// Class with setter to capture React Router redirects
class RouterContext {
  constructor(dispatch) {
    this.dispatch = dispatch
  }

  set status(value) {
    this.dispatch(changeStatus(value))
  }

  set url(value) {
    this.dispatch(changeUrl(value))
  }
}

function useReduxHttp(state) {
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(redirect(state.url))
  }, [state.url])
}

export function Router(props) {
  const [state, dispatch] = React.useReducer(reducer, {
    statusCode: null,
    url: null,
  })

  const context = React.useMemo(() => new RouterContext(dispatch), [dispatch])

  const request = Http.useRequest()

  const basename = Http.useBasename()

  // TODO: Remove when migrated away from Redux
  useReduxHttp(state)

  if (process.browser) {
    return <ReactRouter.BrowserRouter {...props} basename={basename} />
  }

  return (
    <ReactRouter.StaticRouter
      {...props}
      basename={basename}
      context={context}
      location={request.url}
    />
  )
}

Router.defaultProps = {
  context: {},
}

Router.propTypes = {
  context: PropTypes.object,
}
