import * as R from 'ramda'

const DEFAULT_VARIANT = 'default'

function resolveList(items) {
  const result = []

  for (const item of items) {
    result.push(item.value)

    if (item.name) {
      result[item.name] = item.value
    }
  }

  return result
}

function resolveObject(items) {
  const result = {}

  for (const item of items) {
    result[item.name] = item.value
  }

  return result
}

function resolveColors(colors) {
  const variants = R.groupBy(
    color => R.defaultTo(DEFAULT_VARIANT, color.variant),
    colors
  )

  if (R.any(R.propEq('__type', 'alias'), colors)) {
    return resolveColors(
      R.map(color => {
        if (color.__type !== 'alias') {
          return color
        }

        if (!color.variant) {
          const resolvedColor = R.find(
            R.propEq('name', color.value),
            variants.default
          )
          return {
            ...resolvedColor,
            name: color.name,
          }
        }

        const resolvedColor = R.defaultTo(
          R.find(R.propEq('name', color.value), variants.default),
          R.find(R.propEq('name', color.value), variants[color.variant])
        )

        return {
          ...resolvedColor,
          name: color.name,
          variant: color.variant,
        }
      }, colors)
    )
  }

  return R.reduce(
    (colors, color) => {
      if (color.variant) {
        return R.assocPath(
          ['__variants', color.variant, color.name],
          color.value,
          colors
        )
      }

      return R.assoc(color.name, color.value, colors)
    },
    {},
    colors
  )
}

export function createTheme({
  breakpoints = [],
  colors = [],
  fonts = [],
  fontSizes = [],
  radii = [],
  space = [],
  shadows = [],
  variants = [],
  zIndices = [],
}) {
  return {
    breakpoints: resolveList(breakpoints),
    colors: resolveColors(colors),
    fonts: resolveList(fonts),
    fontSizes: resolveList(fontSizes),
    radii: resolveList(radii),
    space: resolveList(space),
    shadows: resolveList(shadows),
    zIndices: resolveList(zIndices),
    ...resolveObject(variants),
  }
}
