import * as R from 'ramda'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'
import * as React from 'react'
import { Helmet } from 'react-helmet-async'

import * as Herz from '@rushplay/herz'

import * as Configuration from './configuration'
import { useServerConfiguration } from './server-configuration'

function useAvailableLocales() {
  const configuration = useServerConfiguration()
  return R.reduce(
    (locales, country) =>
      R.concat(locales, Herz.Utils.Arrays.pluck('slug', country.locales)),
    [],
    configuration.countries
  )
}

export function SeoHreflangs() {
  const availableLocales = useAvailableLocales()
  const location = ReactRouter.useLocation()
  const origin = ReactRedux.useSelector(state =>
    Configuration.getOrigin(state.configuration)
  )

  return (
    <Helmet>
      {R.map(
        locale => (
          <link
            key={locale}
            rel="alternate"
            hrefLang={locale}
            href={`${origin}/${locale}${location.pathname}${location.search}`}
          />
        ),
        availableLocales
      )}
      <link rel="alternate" hrefLang="x-default" href={origin} />
    </Helmet>
  )
}
