// Custom HTTP(S) agents enable keep-alive connections to requested resources,
// which improves performance by reusing existing connections to APIs instead
// of creating new one for each request

import * as R from 'ramda'
import URL from 'url'

export const httpAgent = process.browser
  ? null
  : // Use `require` to enable tree-shaking
    new require('http').Agent({
      keepAlive: true,
      keepAliveMsecs: 30000,
    })

export const httpsAgent = process.browser
  ? null
  : // Use `require` to enable tree-shaking
    new require('https').Agent({
      keepAlive: true,
      keepAliveMsecs: 30000,
    })

export function getAgentByUrl(url) {
  if (process.browser) {
    return null
  }

  const { protocol } = URL.parse(url)

  if (protocol === 'http:') {
    return httpAgent
  } else {
    return httpsAgent
  }
}

export function middleware() {
  if (process.browser) {
    return next => action => next(action)
  }

  return next => action => {
    if (action.type === 'EFFECT_FETCH') {
      return next(
        R.assocPath(
          ['payload', 'params', 'agent'],
          getAgentByUrl(action.payload.url),
          action
        )
      )
    }

    return next(action)
  }
}
