import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router-dom'

import * as Player from './player'

export function useProfileRedirect() {
  const history = ReactRouter.useHistory()
  const enforceProfileRedirect = ReactRedux.useSelector(state =>
    Player.getEnforceProfileRedirect(state.player)
  )
  const dispatch = ReactRedux.useDispatch()

  React.useEffect(() => {
    if (enforceProfileRedirect) {
      history.push('/account/my-profile')
      dispatch(Player.updateEnforceDeposit(false))
    }
  }, [enforceProfileRedirect])
}
