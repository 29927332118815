// TODO: rename to http.js when reducer is removed

/**
 * HTTP module.
 *
 * Set of abstractions for easier work with HTTP Request and Response objects.
 * Especially inside React.
 *
 * Main goal of the module is to make accessing request/response APIs in the
 * application as straightforward as possible. Most of the quirks shall be
 * handled in methods provided by module instead of cluttering components and
 * other parts of application.
 *
 * @module http
 */

import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'
import URL from 'url'

import * as Utils from './utils'

function noop() {}

const HttpContext = React.createContext(null)

/**
 * React Context provider used to allow access to request and response objects
 * anywhere in component tree.
 *
 * Recommended to be as close as possible to the top of component tree.
 */
export function Provider(props) {
  const request = React.useMemo(
    () => (process.browser ? null : props.request),
    [props.request]
  )

  // To propagate changes to `Response` object to components subscribed to
  // context, we shall bump response version whenever change was made and
  // depend on it to trigger rerenders
  const [responseVersion, setResponseVersion] = React.useState(0)
  const response = React.useMemo(
    () => (process.browser ? null : props.response),
    [props.response]
  )

  const updateResponse = React.useCallback(
    fn => {
      fn(response)
      setResponseVersion(R.inc)
    },
    [response, setResponseVersion]
  )

  const value = React.useMemo(
    () => ({
      request,
      response,
      responseVersion,
      updateResponse,
    }),
    [request, response, responseVersion, updateResponse]
  )

  return (
    <HttpContext.Provider value={value}>{props.children}</HttpContext.Provider>
  )
}

Provider.displayName = 'Http.Provider'

Provider.propTypes = {
  children: PropTypes.element,
  request: PropTypes.object,
  response: PropTypes.object,
}

function useContext() {
  return React.useContext(HttpContext)
}

export function useRequest() {
  const { request } = useContext()
  return request
}

export function useRequestHeader(name) {
  const request = useRequest()

  if (request == null) {
    return null
  }

  return request.headers[R.toLower(name)]
}

export function useResponse() {
  const { response } = useContext()
  return response
}

export function useResponseHeader(name) {
  const { response, updateResponse } = useContext()

  const setHeader = React.useCallback(
    value => {
      if (typeof value === 'function') {
        updateResponse(response => response.setHeader(name, value(response)))
      }
    },
    [name, updateResponse]
  )

  if (response == null) {
    return [null, noop]
  }

  const header = response.getHeader(name)

  return [header, setHeader]
}

export function useRawUrl() {
  const request = useRequest()

  if (process.browser) {
    return `${window.location.pathname}${window.location.search}`
  }

  if (request == null) {
    return null
  }

  return request.url
}

export function useParsedUrl() {
  const url = useRawUrl()

  if (url == null) {
    return null
  }

  return URL.parse(url, true)
}

export function useParsedQuery() {
  const url = useParsedUrl()

  return R.pathOr({}, ['query'], url)
}

export function useLocale() {
  const url = useRawUrl()
  return Utils.Http.localeFromUrl(url)
}

export function useBasename() {
  const { slug } = useLocale()

  if (slug) {
    return `/${slug}`
  }

  return '/'
}

export function useUrl() {
  const basename = useBasename()
  const url = useRawUrl()

  if (basename === '/') {
    return url
  }

  return R.replace(basename, '', url)
}
