import * as React from 'react'

import * as Herz from '@rushplay/herz'

import * as Constants from './constants'
import * as Cookies from './cookies-module'

export function useReturningPlayerListener() {
  const { authenticated } = Herz.Auth.useSession()
  const [returningPlayer, setReturningPLayer] = Cookies.useCookie(
    Constants.CookieKeys.RETURNING_PLAYER
  )

  React.useEffect(() => {
    if (authenticated && !returningPlayer) {
      setReturningPLayer(true, {
        httpOnly: false,
        maxAge: 365 * 24 * 60 * 60,
        path: '/',
      })
    }
  }, [authenticated, returningPlayer])
}
