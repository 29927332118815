import * as ReactRedux from 'react-redux'
import React from 'react'

import * as Cookies from './cookies-module'
import * as Constants from './constants'
import * as Player from './player'

export function usePlayerListener() {
  const [abUserID, setAbUserId] = Cookies.useCookie(
    Constants.CookieKeys.AB_USER_ID
  )
  const userId = ReactRedux.useSelector(state =>
    Player.getUsername(state.player)
  )

  React.useEffect(() => {
    if (userId) {
      if (abUserID !== userId) {
        setAbUserId(userId, {
          httpOnly: false,
          maxAge: 365 * 24 * 60 * 60,
          path: '/',
        })
      }
    }
  }, [userId])
}
