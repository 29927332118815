import * as ReactRedux from 'react-redux'
import { createSelector } from 'reselect'

import * as Analytics from '@rushplay/analytics'
import * as casino from '@rushplay/casino'
import * as Offers from '@rushplay/offers'

import * as Constants from './constants'
import * as Cookies from './cookies-module'
import * as Inventory from './inventory'
import * as Player from './player'
import * as gameCatalog from './game-catalog'
import * as pickedGames from './picked-games'
import * as Promotions from './promotions'

function getAmountCents(_, props) {
  return props.amountCents
}

/**
 * @param {Object} state
 * @param {Object} props
 * @returns {Object} game
 */
export const getGameById = createSelector(
  [
    (state, props) => pickedGames.getGame(state.pickedGames, { id: props.id }),
    (state, props) => gameCatalog.getGame(state.gameCatalog, { id: props.id }),
    (state, props) => casino.selectors.getGame(state.casino, { id: props.id }),
  ],
  (pickedGame, catalogGame, casinoGame) => {
    return pickedGame || catalogGame || casinoGame || {}
  }
)

/**
 * @param {Object} state
 * @param {Object} props
 * @param {number} props.amountCents
 * @returns {array} of calculated offers
 */
export const getCalculatedOffers = createSelector(
  [
    state => state.offers,
    getAmountCents,
    state => Player.getDepositCount(state.player),
  ],
  (state, amountCents = 0, depositNumber = 0) =>
    Offers.getCalculatedOffers(state, {
      amountCents,
      depositNumber,
    })
)

/**
 * @param {Object} state
 * @returns {boolean} has promotion notifications
 */
export const hasPromotionNotifications = createSelector(
  [
    state => Promotions.hasUnseenPromotions(state.promotions),
    state => Inventory.hasUnseenItems(state.inventory),
  ],
  (hasUnseenPromotions, hasUnseenInventory) =>
    hasUnseenPromotions || hasUnseenInventory
)

/**
 * @param {Object} state
 * @returns {boolean} has any kind of notifications
 */
export const hasNotifications = createSelector(
  [hasPromotionNotifications],
  hasPromotionNotifications => hasPromotionNotifications
)
/**
 * @param {Object} state
 * @returns {string} affiliateClickId
 */
export const getAffiliateClickId = createSelector(
  [
    state => Player.getAffiliateClickId(state.player),
    state => Analytics.getClickId(state.analytics),
  ],
  (playerClickId, analyticsClickId) => playerClickId || analyticsClickId
)

/**
 * Check if user needs to verify their phone number
 *
 * @returns {boolean}
 */
export function useIsPhoneVerificationRequired() {
  const isPhoneVerificationRequiredFromStore = ReactRedux.useSelector(state =>
    Player.isPhoneVerificationRequired(state.player)
  )
  const [isPhoneVerificationRequiredFromCookies] = Cookies.useCookie(
    Constants.CookieKeys.IS_PHONE_VERIFICATION_REQUIRED
  )
  return (
    (isPhoneVerificationRequiredFromCookies &&
      JSON.parse(isPhoneVerificationRequiredFromCookies)) ||
    isPhoneVerificationRequiredFromStore
  )
}
