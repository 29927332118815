import * as React from 'react'
import PropTypes from 'prop-types'

import * as Herz from '@rushplay/herz'
import * as Common from '@rushplay/common'

import * as DebugTranslations from './dev-tools/debug-translations'
import { currencies } from './amount'
import { useServerConfiguration } from './server-configuration'

export function Provider(props) {
  const configuration = useServerConfiguration()

  const globals = React.useMemo(
    () => ({
      brandEmail: configuration?.supportEmail,
      brandName: 'Winningdays.com',
      currencyCode: configuration?.currency?.code,
      locale: configuration?.locale?.code || 'en-AU',
    }),
    [
      configuration?.supportEmail,
      configuration?.currency?.code,
      configuration?.locale?.code,
    ]
  )
  const { debug } = DebugTranslations.useContext()

  const processors = React.useMemo(() => {
    return {
      currency(value, data) {
        const currency = currencies[data.currencyCode]

        if (process.env.NODE_ENV !== 'production') {
          if (currency == null) {
            // eslint-disable-next-line no-console
            console.error(`"${data.currencyCode}" is an unknown currency`)
          }
        }

        return Common.toAmount({ currency, locale: data.locale }, value)
      },
    }
  }, [currencies])

  return (
    <Herz.I18n.LoaderProvider loader={props.i18nLoader}>
      <Herz.I18n.TranslationProvider
        debug={debug}
        globals={globals}
        language={configuration?.locale?.code || 'en-AU'}
        processors={processors}
      >
        {props.children}
      </Herz.I18n.TranslationProvider>
    </Herz.I18n.LoaderProvider>
  )
}

Provider.displayName = 'HerzI18nProvider.Provider'

Provider.propTypes = {
  children: PropTypes.node,
  i18nLoader: PropTypes.object,
}
