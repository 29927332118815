import * as types from './types'

/**
 * @param {string} catalog
 * @param {Array.<number>} categoriesIds
 */
export function updateGameCatalog(catalog, categoriesIds) {
  return {
    type: types.GAME_CATALOG_UPDATED,
    payload: {
      catalog,
      categoriesIds,
    },
  }
}

/**
 * @param {Array.<Object>} categories Categories data
 */
export function updateGameCatalogCategories(categories) {
  return {
    type: types.GAME_CATALOG_CATEGORIES_UPDATED,
    payload: {
      categories,
    },
  }
}

/**
 * @param {Array.<Object>} sections Sections data
 */
export function updateGameCatalogSections(sections) {
  return {
    type: types.GAME_CATALOG_SECTIONS_UPDATED,
    payload: {
      sections,
    },
  }
}

/**
 * @param {Array.<Object>} games Games
 */
export function updateGameCatalogGames(games) {
  return {
    type: types.GAME_CATALOG_GAMES_UPDATED,
    payload: {
      games,
    },
  }
}
