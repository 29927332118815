import PropTypes from 'prop-types'

const reduxStore = PropTypes.shape({
  dispatch: PropTypes.func.isRequired,
  getState: PropTypes.func.isRequired,
  subscribe: PropTypes.func.isRequired,
})

export default Object.assign(
  {
    reduxStore,
  },
  PropTypes
)
