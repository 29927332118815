import * as R from 'ramda'
import { combineReducers } from 'redux'

import * as types from './types'

export * from './actions'
export * from './selectors'

function concatIds(key) {
  return (k, l, r) => (k === key ? R.uniq(R.concat(l, r)) : l)
}

export function catalogs(state = {}, action) {
  switch (action.type) {
    case types.GAME_CATALOG_UPDATED: {
      return R.assocPath(
        [action.payload.catalog],
        {
          id: action.payload.catalog,
          categories: action.payload.categoriesIds,
        },
        state
      )
    }

    default: {
      return state
    }
  }
}

export function categories(state = {}, action) {
  switch (action.type) {
    case types.GAME_CATALOG_CATEGORIES_UPDATED: {
      return R.mergeDeepWithKey(
        concatIds('sections'),
        state,
        action.payload.categories
      )
    }

    default: {
      return state
    }
  }
}

export function sections(state = {}, action) {
  switch (action.type) {
    case types.GAME_CATALOG_SECTIONS_UPDATED: {
      return R.mergeDeepWithKey(
        concatIds('games'),
        state,
        action.payload.sections
      )
    }

    default: {
      return state
    }
  }
}

export function games(state = {}, action) {
  switch (action.type) {
    case types.GAME_CATALOG_GAMES_UPDATED: {
      return R.mergeDeepRight(state, action.payload.games)
    }

    default: {
      return state
    }
  }
}

export const reducer = combineReducers({
  catalogs,
  categories,
  games,
  sections,
})
