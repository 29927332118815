import * as React from 'react'

// Removes SVG from document flow to avoid its intrinsic dimensions to affect
// page layout (adding empty space, pushing down the page)
const style = { position: 'absolute' }

export function ClipPaths() {
  return (
    <svg height="0" width="0" style={style} xmlns="http://www.w3.org/2000/svg">
      <defs>
        <clipPath id="left">
          <path d="M0,0 L0,23 C1.55561363e-15,35.7025492 10.2974508,46 23,46 L40,46 C62.09139,46 80,63.90861 80,86 L80,127 L80,127 L0,127 L0,0 Z" />
        </clipPath>
        <clipPath id="right">
          <path
            d="M0,0 L0,23 C1.55561363e-15,35.7025492 10.2974508,46 23,46 L40,46 C62.09139,46 80,63.90861 80,86 L80,127 L80,127 L0,127 L0,0 Z"
            transform="translate(40.000000, 63.500000) scale(-1, 1) translate(-40.000000, -63.500000) "
          />
        </clipPath>
      </defs>
    </svg>
  )
}
