import * as ReactRedux from 'react-redux'
import * as React from 'react'

import * as Notifications from '@rushplay/notifications'

import * as Constants from './constants'
import * as Cookies from './cookies-module'
import * as Player from './player'
import * as ReduxCookies from './cookies'

export function useOfferClaimedListener() {
  const dispatch = ReactRedux.useDispatch()
  const [offerClaimed] = Cookies.useCookie(Constants.CookieKeys.OFFER_CLAIMED)
  const depositCount = ReactRedux.useSelector(state =>
    Player.getDepositCount(state.player)
  )

  React.useEffect(() => {
    if (offerClaimed && depositCount < 1) {
      dispatch(ReduxCookies.remove('offer_claimed'))
      dispatch(
        Notifications.add({
          level: 'info',
          message: 'errors.login.offer-already-claimed',
        })
      )
    }
  }, [dispatch, depositCount, offerClaimed])
}
