import * as ReactRedux from 'react-redux'
import * as React from 'react'

import * as Jurisdiction from '@rushplay/compliance/jurisdiction'

import * as Configuration from './configuration'

function getCountryCode(state) {
  return Configuration.getCountryCode(state.configuration)
}

/**
 * Maintain country-dependent state up-to-date
 */
export function useCountryCodeListener() {
  // Use country code from Redux state (instead of ServerConfiguration) to make
  // sure API client will pick up correct value
  const countryCode = ReactRedux.useSelector(getCountryCode)
  const dispatch = ReactRedux.useDispatch()

  // Fetch data on country code change
  React.useEffect(() => {
    dispatch(Configuration.fetch())
    dispatch(Jurisdiction.fetch())
  }, [countryCode])
}
