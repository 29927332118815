import { PassThrough, Transform } from 'stream'

export function concatStreams(...streams) {
  const destination = new PassThrough()

  for (const stream of streams) {
    const index = streams.indexOf(stream)
    const isFirst = index === 0
    const nextStream = streams[index + 1]

    stream.on('error', error => {
      destination.destroy(error)
    })

    stream.on('end', () => {
      if (nextStream) {
        nextStream.pipe(destination, { end: false })
      } else {
        destination.end()
      }
    })

    if (isFirst) {
      stream.pipe(destination, { end: false })
    }
  }

  return destination
}

export class ReplaceOnceTransform extends Transform {
  /**
   * Create transform stream to search and replace given sequence once.
   *
   * @param {object} options
   * @param {Buffer} options.match Buffer to look for in incoming chunks
   * @param {ReadableStream} options.replacement
   *   A readable stream of data that replaces matched chunk
   */
  constructor({ match, replacement, ...options }) {
    super(options)

    this.match = match
    this.replacement = replacement

    this.cache = Buffer.from('')
  }

  async _transform(chunk, encoding, callback) {
    if (this.match == null) {
      return callback(null, chunk)
    }

    const source = Buffer.concat([this.cache, chunk])

    const index = source.indexOf(this.match)

    if (index < 0) {
      if (chunk.length >= this.match.length) {
        this.push(this.cache)
        this.cache = chunk
      } else {
        this.cache = source
      }

      return callback()
    }

    const prefix = source.slice(0, index)
    const suffix = source.slice(index + this.match.length)

    this.match = null

    this.push(prefix)

    for await (const chunk of this.replacement) {
      this.push(chunk)
    }

    callback(null, suffix)
  }
}
