import * as R from 'ramda'
import { pipe, tap } from 'wonka'

import * as logger from './logger'

export function loggerExchange({ forward }) {
  return ops$ =>
    pipe(
      ops$,
      tap(op => {
        logger.graphql.info(
          R.pick(['key', 'query', 'variables'], op),
          'Incoming operation'
        )
      }),
      forward,
      tap(result => {
        logger.graphql.info(
          R.pick(['key', 'query', 'variables'], result),
          'Completed operation'
        )
      })
    )
}
