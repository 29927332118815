import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'

import * as websockets from '@rushplay/websockets'
import * as Herz from '@rushplay/herz'

import * as configuration from './configuration'
import * as player from './player'

export function useWebSocketListener() {
  const dispatch = useDispatch()
  const { token: sessionToken } = Herz.Auth.useSession()

  const username = useSelector(state => player.getUsername(state.player))
  const {
    authEndpoint,
    cluster,
    enabledTransports,
    encrypted,
    key,
  } = useSelector(state => configuration.getPusher(state.configuration))

  // Init and re-init on token change
  React.useEffect(() => {
    dispatch(
      websockets.init(key, {
        auth: {
          params: {
            username,
            sessionToken,
          },
        },
        cluster: cluster,
        authEndpoint: authEndpoint,
        encrypted: encrypted,
        enabledTransports: enabledTransports,
      })
    )
  }, [
    authEndpoint,
    cluster,
    enabledTransports,
    encrypted,
    key,
    sessionToken,
    username,
  ])
}
