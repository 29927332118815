import * as React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet-async'

/**
 * Use third-party fonts without blocking rendering
 *
 * The font is prefetched anyway to transition to it as fast as possible
 *
 * @param props
 * @param props.href {!string} External font CSS URL
 */
export function ExternalFont(props) {
  // Using "print" initially to enforce browser to ignore stylesheet
  const [media, setMedia] = React.useState('print')

  // `useEffect` is not executed on server thus `media` will be set to `print`
  // `useEffect` is executed on client after hydration is over thus swapping
  // `media` without causing conflicts between server HTML and initial render
  // result
  React.useEffect(() => {
    // Set target media as "screen" to apply stylesheet
    setMedia('screen')
  }, [])

  return (
    <Helmet>
      <link rel="prefetch" href={props.href} crossOrigin="anonymous" />
      <link href={props.href} rel="stylesheet" media={media} />
    </Helmet>
  )
}

ExternalFont.propTypes = {
  href: PropTypes.string.isRequired,
}
