import * as R from 'ramda'
import * as React from 'react'

export function useCssCustomProperties() {
  const [properties, setProperties] = React.useState({})

  React.useEffect(() => {
    function handleResize() {
      // This is a fix for iOS Safari (and other mobile browsers that have
      // variable viewport height depending on browser UI visibility) as 100vh is
      // equal to maximum possible viewport height (without taking expanded
      // browser UI into account).
      //
      // It is initially introduced for mobile games rendered in iframe to avoid
      // them being covered by browser UI parts which happens if `vh` is used.

      setProperties(state => {
        const nextState = { '--window-inner-height': `${window.innerHeight}px` }

        // avoid redundant render if state is the same
        if (R.equals(state, nextState)) {
          return state
        }

        return nextState
      })
    }

    window.addEventListener('resize', handleResize)
    handleResize()
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  React.useEffect(() => {
    for (const property in properties) {
      const value = document.documentElement.style.getPropertyValue(property)
      if (value !== properties[property]) {
        document.documentElement.style.setProperty(
          property,
          properties[property]
        )
      }
    }
  }, [properties])
}
