import * as Urql from 'urql'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as Logger from './logger'

const ServerConfigurationContext = React.createContext(null)

const configurationQuery = `
  query Configuration {
    configuration {
      brand
      cdnHost
      cdnPrefix
      countries {
        alpha2
        countryCode
        name
        locales {
          code
          default
          language
          slug
        }
      }
      country {
        access
        alpha2
        countryCode
        enabled
        name
        localCurrency
        locales {
          language
          slug
        }
      }
      currency {
        code
      }
      locale {
        code
        language
        slug
      }
      liveChatKey
      liveChatDepartment {
        fallback
        primary
      }
      supportEmail
    }
  }
`

export function ServerConfigurationProvider(props) {
  const [response] = Urql.useQuery({
    query: configurationQuery,
  })

  const value = response.data?.configuration ?? {}

  if (response.error) {
    Logger.react.error(response.error)
    throw new Error('errors.configuration.fetch-failure')
  }

  if (
    response.fetching ||
    (Object.keys(value).length === 0 && value.constructor === Object)
  ) {
    return null
  }

  return (
    <ServerConfigurationContext.Provider value={value}>
      {props.children}
    </ServerConfigurationContext.Provider>
  )
}

ServerConfigurationProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export function useServerConfiguration() {
  const context = React.useContext(ServerConfigurationContext)
  if (context === undefined) {
    throw new Error(
      'useServerConfiguration must be used within a ServerConfigurationProvider'
    )
  }
  return context
}
