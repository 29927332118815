import * as React from 'react'
import PropTypes from 'prop-types'

import { lazy as loadable } from '@loadable/component'

const ClientErrorPage = loadable(() => import('./client-error-page'))

export class GlobalErrorBoundary extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line no-console
    console.error(error, errorInfo)
  }

  render() {
    if (this.state.hasError) {
      return <ClientErrorPage />
    }

    return this.props.children
  }
}

GlobalErrorBoundary.propTypes = {
  children: PropTypes.node,
}
