import * as R from 'ramda'
import * as React from 'react'
import PropTypes from 'prop-types'

import * as common from '@rushplay/common'
import css from '@styled-system/css'
import styled from '@emotion/styled'
import { NotificationLevel } from '@rushplay/notifications'

import * as icons from './icons'
import * as Theming from './theming'

const levelColor = {
  error: 'danger',
  info: 'page-nav-blue',
  success: 'success',
  warning: 'warning',
}

const levelIcon = {
  error: icons.Error,
  info: icons.Info,
  success: icons.CheckCircle,
  warning: icons.Warning,
}

const CloseWrapper = styled.div`
  ${css({
    py: 1,
    color: 'input',
    fontSize: 6,
    '&:hover, &:active': {
      color: 'inputHover',
    },
  })}
  display: inline-flex;
`

export function Toast(props) {
  const Icon = levelIcon[props.variant] || icons.Remove
  const color = levelColor[props.variant] || 'disabled'

  return (
    <Theming.Alternative>
      <common.Box
        backgroundColor="g-bg"
        boxShadow={0}
        color="g-text"
        fontSize={2}
        borderRadius={0}
        overflow="hidden"
        display="flex"
        justifyContent="space-between"
        data-testid="toast"
      >
        <common.Box display="inline-flex">
          <common.Box
            padding={1}
            display="inline-flex"
            alignItems="center"
            color="g-bg"
            backgroundColor={color}
            fontSize={6}
            data-testid={`toast.icon.${color}`}
          >
            <Icon />
          </common.Box>
          <common.Box
            padding={1}
            display="inline-flex"
            flexDirection="column"
            justifyContent="center"
            pr={1}
          >
            {props.title && (
              <common.Text
                fontFamily="head"
                fontSize={3}
                fontWeight="bold"
                as="p"
              >
                {props.title}
              </common.Text>
            )}
            <common.Text fontSize={1} as="p">
              {props.children}
            </common.Text>
          </common.Box>
        </common.Box>
        {props.onDismiss && (
          <CloseWrapper onClick={props.onDismiss}>
            <common.Box
              padding={1}
              borderLeft="1px solid"
              borderColor="input"
              display="inline-flex"
              alignItems="center"
              data-testid="toast.close-button"
            >
              <icons.Clear />
            </common.Box>
          </CloseWrapper>
        )}
      </common.Box>
    </Theming.Alternative>
  )
}

Toast.propTypes = {
  children: PropTypes.string.isRequired,
  title: PropTypes.string,
  variant: PropTypes.oneOf(R.values(NotificationLevel)),
  onDismiss: PropTypes.func,
}
