import * as React from 'react'
import { Helmet } from 'react-helmet-async'
import { nanoid } from 'nanoid'

import * as Herz from '@rushplay/herz'

import * as Cookies from './cookies-module'
import * as Constants from './constants'

export function generateSessionId() {
  const timestamp = Date.now()
  const id = nanoid()
  return `HG.${timestamp}.${id}`
}

// This wrapper enables SEON
export function SeonProvider(props) {
  const [deviceId, setDeviceId] = Cookies.useCookie(
    Constants.CookieKeys.DEVICE_ID
  )
  const [gid] = Cookies.useCookie(Constants.CookieKeys.GID)

  React.useEffect(() => {
    if (!deviceId) {
      const newSessionId = gid || generateSessionId()
      setDeviceId(
        newSessionId,
        Constants.CookieOptions[Constants.CookieKeys.DEVICE_ID]
      )
    }
  }, [deviceId, gid, setDeviceId])

  return (
    <Herz.Seon.FingerprintProvider
      {...props}
      disabled={!process.browser}
      renderScript={props => (
        <Helmet>
          <script {...props} />
        </Helmet>
      )}
      sessionId={deviceId}
    />
  )
}
